import React, { SVGProps } from "react";

const SvgDots = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={50}
    height={80}
    viewBox="0 0 50 80"
    fill="currentColor"
    {...props}
  >
    <path d="M20 10C20 15.5228 15.5228 20 10 20C4.47715 20 0 15.5228 0 10C0 4.47715 4.47715 0 10 0C15.5228 0 20 4.47715 20 10Z" />
    <path d="M20 70C20 75.5228 15.5228 80 10 80C4.47715 80 0 75.5228 0 70C0 64.4772 4.47715 60 10 60C15.5228 60 20 64.4772 20 70Z" />
    <path d="M50 10C50 15.5228 45.5228 20 40 20C34.4772 20 30 15.5228 30 10C30 4.47715 34.4772 0 40 0C45.5228 0 50 4.47715 50 10Z" />
    <path d="M50 70C50 75.5228 45.5228 80 40 80C34.4772 80 30 75.5228 30 70C30 64.4772 34.4772 60 40 60C45.5228 60 50 64.4772 50 70Z" />
    <path d="M20 40C20 45.5228 15.5228 50 10 50C4.47715 50 0 45.5228 0 40C0 34.4772 4.47715 30 10 30C15.5228 30 20 34.4772 20 40Z" />
    <path d="M50 40C50 45.5228 45.5228 50 40 50C34.4772 50 30 45.5228 30 40C30 34.4772 34.4772 30 40 30C45.5228 30 50 34.4772 50 40Z" />
  </svg>
);

export default SvgDots;
