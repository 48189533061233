import React from "react";
import styled, { ThemeProvider } from "styled-components";
import ColorStrip, { StyledColorStrip } from "./components/ColorStrip";
import Fonts from "./designSystem/Fonts";
import GlobalStyles from "./designSystem/GlobalStyles";
import theme, { breakpoints } from "./designSystem/theme";
import Settings, { StyledSettings } from "./components/Settings";

function App() {
  return (
    <ThemeProvider theme={theme}>
      <Fonts />
      <GlobalStyles />
      <StyledApp>
        <h1>
          Color
          <br />
          Shade
          <br />
          Creator
        </h1>
        <div className="grid-container">
          <Settings />
          <ColorStrip />
        </div>
      </StyledApp>
    </ThemeProvider>
  );
}

export const StyledApp = styled("main")`
  padding: 40px 12px 100px;

  @media (min-width: ${breakpoints.tablet}px) {
    padding: 40px 40px 200px;
  }

  @media (min-width: ${breakpoints.desktop}px) {
    padding-left: 100px;
  }

  > h1 {
    font-size: 30px;
    margin: 0 0 40px;
    line-height: 1;
    text-align: left;

    @media (min-width: ${breakpoints.tablet}px) {
      font-size: 40px;
      margin: 0 0 60px;
    }
  }

  .grid-container {
    display: grid;
    grid-template-columns: 1fr;
    align-items: start;

    ${StyledSettings} {
      margin-bottom: 40px;
    }

    ${StyledColorStrip} {
    }

    @media (min-width: ${breakpoints.tablet}px) {
      grid-template-columns: auto 1fr;

      ${StyledSettings} {
        order: 2;
      }

      ${StyledColorStrip} {
        order: 1;
      }
    }
  }
`;

export default App;
