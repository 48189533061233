import { normalize } from "polished";
import { Theme } from "./theme";
import { createGlobalStyle } from "styled-components";

const GlobalStyles = createGlobalStyle<{ theme: Theme }>`
	${normalize()};
	
	html,
	body {
		${({ theme }) => theme.typography.baseFont};
		min-height: 500px;
		min-width: 320px;
		overflow-x: hidden;
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
		-webkit-tap-highlight-color: rgba(255, 255, 255, 0);
		touch-action: manipulation;
		background-color: ${({ theme }) => theme.colors.background};
		color: ${({ theme }) => theme.colors.text};
	}

	a {
		color: inherit;
		text-decoration: none;
		cursor: pointer;
	}

	p {
		margin: ${({ theme }) => theme.spacing.space8}px 0;

		&:first-child:not(style) {
			margin-top: 0;
		}

		&:last-child:not(style) {
			margin-bottom: 0;
		}
	}
	
	ul {
		list-style-position: outside;
		padding-left: 1.2em;
	}

	button,
	input[type='submit'],
	input[type='reset'] {
		background: none;
		color: inherit;
		border: none;
		padding: 0;
		font: inherit;
		cursor: pointer;
		outline: inherit;
	}

	.only-small-screen {
		@media (min-width: ${({ theme }) => theme.breakpoints.tablet}px) {
			display: none;
		}
	}

	.only-big-screen {
		display: none;

		@media (min-width: ${({ theme }) => theme.breakpoints.tablet}px) {
			display: block;
		}
	}
`;

export default GlobalStyles;
