import React, { useCallback, useEffect, useState } from "react";
import styled from "styled-components";
import { useShadeActions, useShadeColors } from "../context/shadesContext";
import { ReactSortable, Sortable } from "react-sortablejs";
import Color from "color";
import SvgDots from "../designSystem/icons/SvgDots";
import SvgCross from "../designSystem/icons/SvgCross";
import SvgPencil from "../designSystem/icons/SvgPencil";
import SvgCheckmark from "../designSystem/icons/SvgCheckmark";
import { breakpoints } from "../designSystem/theme";
import { ColorModel } from "../utils/urlHelpers";

type SortableShade = {
  id: string;
  color: Color;
  isFixColor: boolean;
};

type SelectedColor = {
  index: number;
  colorParts: number[];
};

const ColorStrip = () => {
  const { shades, colors, colorModel, showHexValues } = useShadeColors();
  const { removeColor, setColor, moveColor } = useShadeActions();
  const [sortableShades, setSortableShades] = useState<SortableShade[]>([]);
  const [selectedColor, setSelectedColor] = useState<SelectedColor | null>();

  useEffect(() => {
    console.log("update sortable shades");
    setSortableShades(
      shades.map(
        (shade, index) =>
          ({
            id: shade.toString(),
            isFixColor: colors.has(index + 1),
            color: shade,
          } as SortableShade)
      )
    );
  }, [shades]);

  const updateList = useCallback(
    (nextSortableShades: typeof sortableShades) => {
      setSortableShades(nextSortableShades);
    },
    [setSortableShades]
  );

  const handleDragEnd = useCallback(
    (event: Sortable.SortableEvent) => {
      const oldIndex = event.oldIndex;
      const newIndex = event.newIndex;
      if (oldIndex !== undefined && newIndex !== undefined) {
        moveColor(oldIndex + 1, newIndex + 1);
      }
    },
    [moveColor]
  );

  useEffect(() => {
    if (selectedColor) {
      (
        document.querySelector("input[type='text']") as HTMLInputElement
      )?.focus();
    }
  }, [selectedColor]);

  return (
    <StyledColorStrip>
      <ReactSortable
        list={sortableShades}
        setList={updateList}
        onEnd={handleDragEnd}
        filter=".shade:not(.draggable)"
        preventOnFilter={false}
      >
        {sortableShades.map((shade, index) => {
          const isDraggable =
            shade.isFixColor && index > 0 && index < sortableShades.length - 1;
          return (
            <StyledColor
              key={index}
              isColor={shade.isFixColor}
              isDark={shade.color.isDark()}
              draggable={isDraggable}
              selectedColor={selectedColor}
              className={`shade ${isDraggable ? "draggable" : ""}`}
            >
              <div className="index-display">{index + 1}</div>
              <div className="color-field">
                <div
                  className="color-display"
                  style={{
                    backgroundColor: shade.color.rgb().toString(),
                  }}
                />
                {shade.isFixColor ? (
                  <div className="shade-options">
                    {isDraggable ? (
                      <SvgDots className="dots drag-handle" />
                    ) : null}

                    {selectedColor?.index === index + 1 ? (
                      <form
                        onSubmit={(event) => {
                          event.preventDefault();
                          const inputValues = Array.from(
                            (event.target as HTMLFormElement).querySelectorAll(
                              "input"
                            )
                          ).map((element) => element.value);
                          if (colorModel === ColorModel.rgb && showHexValues) {
                            const hexValue = inputValues[0];
                            setColor(
                              index + 1,
                              Color(
                                hexValue.substring(0, 1) === "#"
                                  ? hexValue
                                  : `#${hexValue}`,
                                colorModel
                              )
                            );
                          } else {
                            const colorValues = inputValues.map((value) =>
                              parseInt(value)
                            );
                            setColor(index + 1, Color(colorValues, colorModel));
                          }
                          setSelectedColor(null);
                        }}
                      >
                        <div>{colorModel}</div>
                        {colorModel === ColorModel.rgb && showHexValues ? (
                          <input
                            type="text"
                            key={index}
                            className="hex-value"
                            maxLength={7}
                            defaultValue={shade.color[colorModel]().hex()}
                          />
                        ) : (
                          shade.color[colorModel]()
                            .round(0)
                            .array()
                            .map((value: number, index: number) => (
                              <input
                                type="text"
                                key={index}
                                maxLength={3}
                                defaultValue={value}
                              />
                            ))
                        )}
                        <button type="submit">
                          <SvgCheckmark />
                        </button>
                      </form>
                    ) : (
                      <>
                        <form />
                        <button
                          className="edit-button"
                          onClick={() =>
                            setSelectedColor({
                              index: index + 1,
                              colorParts: shade.color.array(),
                            })
                          }
                        >
                          <SvgPencil />
                        </button>
                      </>
                    )}

                    {isDraggable ? (
                      <button
                        className="clear-button"
                        onClick={() =>
                          selectedColor
                            ? setSelectedColor(null)
                            : removeColor(index + 1)
                        }
                      >
                        <SvgCross />
                      </button>
                    ) : null}
                  </div>
                ) : (
                  <div className="shade-options">
                    <button
                      className="add-button"
                      onClick={() => setColor(index + 1, shade.color)}
                    >
                      <SvgCross />
                    </button>
                  </div>
                )}
              </div>
              <div className="color-string">
                <span>{colorModel}&nbsp;</span>
                <span>
                  {colorModel === ColorModel.rgb && showHexValues
                    ? shade.color[colorModel]().hex()
                    : shade.color[colorModel]().round(0).array().join("/")}
                </span>
              </div>
            </StyledColor>
          );
        })}
      </ReactSortable>
    </StyledColorStrip>
  );
};

export const StyledColorStrip = styled("section")`
  display: flex;
  flex-direction: column;
  width: 100vw;
  max-width: 500px;
`;

export const StyledColor = styled("div")<{
  isColor: boolean;
  isDark: boolean;
  draggable: boolean;
  selectedColor?: SelectedColor | null;
}>`
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  cursor: ${({ draggable }) => (draggable ? "pointer" : "")};
  position: relative;

  .index-display {
    box-sizing: border-box;
    padding-right: 8px;
    height: 100%;
    width: 32px;
    position: absolute;
    left: 0;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    font-weight: bold;
    opacity: 0.3;
  }

  .color-field {
    flex: 0 0 70%;
    height: 100%;
    position: relative;
    color: ${({ isDark }) => (isDark ? "white" : "black")};

    @media (min-width: ${breakpoints.tablet}px) {
      flex: 0 0 70%;
    }

    .color-display {
      position: absolute;
      top: 0;
      left: ${({ isColor }) => (isColor ? "0" : "32px")};
      right: 0;
      bottom: 0;
      transition: 0.3s left ease-out;
    }

    .shade-options {
      box-sizing: border-box;
      width: 100%;
      height: 100%;
      padding: 0 12px 0 ${({ isColor }) => (isColor ? "12px" : `${12 + 32}px`)};
      position: absolute;
      top: 0;
      left: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      pointer-events: none;

      .dots {
        flex: 0 0 auto;
        width: 12px;
        height: auto;
      }

      form {
        flex: 1 1 auto;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        pointer-events: all;

        > div:first-of-type {
          margin-right: 4px;
        }

        input {
          width: 32px;
          height: 24px;
          outline: none;
          border: none;
          margin: 0 0 0 2px;
          box-sizing: border-box;
          text-align: center;
          pointer-events: all;

          &.hex-value {
            width: 70px;
          }
        }

        button[type="submit"] {
          display: block;
          margin-left: 8px;

          > svg {
            width: 14px;
            height: auto;
          }
        }
      }

      .edit-button {
        flex: 0 0 auto;

        > svg {
          width: 14px;
          height: auto;
        }
      }

      .clear-button {
        flex: 0 0 auto;
        margin-left: 12px;

        > svg {
          width: 16px;
          height: auto;
          transform: rotate(45deg);
          transform-origin: center;
        }
      }

      .add-button {
        flex: 0 0 auto;
        opacity: 0.3;
        transition: opacity 0.3s;

        > svg {
          width: 16px;
          height: auto;
        }
      }
    }

    &:hover {
      .color-display {
        left: 0;
      }

      button {
        opacity: 1 !important;
      }
    }
  }

  .color-string {
    margin-left: 8px;
    white-space: nowrap;
    font-size: 12px;
    font-weight: bold;
    opacity: 0.3;

    > span:first-of-type {
      display: none;
      font-weight: normal;

      @media (min-width: ${breakpoints.tablet}px) {
        display: inline;
      }
    }
  }

  button {
    background-color: rgba(0, 0, 0, 0);
    transform: scale(1);
    transition: transform 0.1s;
    pointer-events: auto;

    &:hover {
      transform: scale(1.1);
      opacity: 1;
    }
  }
`;

export default React.memo(ColorStrip);
