import React, { SVGProps } from "react";

const SvgCheckmark = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={93}
    height={84}
    viewBox="0 0 93 84"
    fill="currentColor"
    {...props}
  >
    <path d="M82.1356 0.776321L33.1768 66.1175L7.84957 47.1402L0.303711 57.211L35.7017 83.7337L92.2064 8.32218L82.1356 0.776321Z" />
  </svg>
);

export default SvgCheckmark;
