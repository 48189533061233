import { useCallback, useEffect, useMemo, useState } from "react";
import constate from "constate";
import Color from "color";
import {
  ColorModel,
  parseColorModelFromUrl,
  parseColorsFromUrl,
  parseColorEasingFromUrl,
  updateUrl,
} from "../utils/urlHelpers";
import { mixColor } from "../utils/colorMixer";

// eslint-disable-next-line
(window as any).Color = Color;

const defaultColorMap = new Map([
  [1, [0, 0, 0]],
  [11, [255, 0, 0]],
  [21, [255, 255, 0]],
]);

const useShadesContext = () => {
  const [colorModel, setColorModel] = useState(
    parseColorModelFromUrl() ?? ColorModel.rgb
  );
  const [colorEasing, setColorEasing] = useState(parseColorEasingFromUrl());
  const [showHexValues, setShowHexValues] = useState(true);
  const [colors, setColors] = useState(
    new Map<number, number[]>(parseColorsFromUrl() ?? defaultColorMap)
  );
  const shadesCount = useMemo(() => {
    const indices = Array.from(colors.keys());
    return indices[indices.length - 1];
  }, [colors]);
  const [shades, setShades] = useState([] as Color[]);

  const calculateShades = useCallback(() => {
    console.log("calculate shades");
    const getShadesBetweenColors = (
      startColor: Color,
      endColor: Color,
      steps: number
    ) => {
      const shades = [] as Color[];
      const startColorArray = startColor.array();
      const endColorArray = endColor.array();
      Array.from(Array(steps + 1)).forEach((_, index) => {
        if (index === 0) {
          return;
        }
        // const mixedColor = startColor.mix(endColor, (1.0 / steps) * index)[colorModel]();
        const mixedValues = mixColor({
          color1: startColorArray,
          color2: endColorArray,
          weight: (1.0 / steps) * index,
          colorModel,
          easing: colorEasing,
        });
        const mixedColor = Color(mixedValues, colorModel);
        shades.push(mixedColor);
      });
      return shades;
    };

    const colorIterator = colors.entries();
    let nextColor = colorIterator.next().value as [number, string];
    let previousColor: [number, string] | undefined;
    let nextShades = [Color(nextColor[1], colorModel)];
    while (nextColor) {
      if (previousColor) {
        nextShades = [
          ...nextShades,
          ...getShadesBetweenColors(
            Color(previousColor[1], colorModel),
            Color(nextColor[1], colorModel),
            nextColor[0] - previousColor[0]
          ),
        ];
      }
      previousColor = nextColor;
      nextColor = colorIterator.next().value as [number, string];
    }
    setShades(nextShades);

    // update url
    updateUrl(colorModel, colorEasing, colors);
  }, [setShades, colors, colorModel, colorEasing]);

  useEffect(() => {
    calculateShades();
  }, [colors]);

  useEffect(() => {
    const handlePopState = () => {
      console.log("url change detected");
      const nextColorModel = parseColorModelFromUrl() ?? ColorModel.rgb;
      setColorModel(nextColorModel);
      const nextColorEasing = parseColorEasingFromUrl();
      setColorEasing(nextColorEasing);
      const nextColors = parseColorsFromUrl() ?? defaultColorMap;
      setColors(new Map<number, number[]>(nextColors));
    };
    window.addEventListener("popstate", handlePopState);
    return () => {
      window.removeEventListener("popstate", handlePopState);
    };
  }, []);

  const setColor = useCallback(
    (index: number, color: Color) => {
      console.log(`set new color at index ${index}`);
      let nextColors = new Map(colors);
      nextColors.set(index, color.array());
      nextColors = new Map(
        Array.from(nextColors.entries()).sort(([key1], [key2]) =>
          key1 < key2 ? -1 : 1
        )
      );
      setColors(nextColors);
    },
    [colors, setColors]
  );

  const moveColor = useCallback(
    (oldIndex: number, newIndex: number) => {
      const oldValue = colors.get(oldIndex);
      if (oldIndex !== newIndex && oldValue) {
        console.log(`move color`);
        const nextColors = new Map(colors);
        nextColors.set(newIndex, oldValue);
        nextColors.delete(oldIndex);
        const sortedNextColors = new Map(
          Array.from(nextColors.entries()).sort(([key1], [key2]) =>
            key1 < key2 ? -1 : 1
          )
        );
        setColors(sortedNextColors);
      }
    },
    [colors, setColors]
  );

  const removeColor = useCallback(
    (index: number) => {
      console.log(`remove color at index ${index}`);
      const nextColors = new Map(colors);
      nextColors.delete(index);
      setColors(nextColors);
    },
    [colors, setColors]
  );

  const changeColorModel = useCallback(
    (nextColorModel: ColorModel) => {
      console.log(`change color model to "${nextColorModel}"`);
      const nextColors: [number, number[]][] = Array.from(colors.entries()).map(
        (color) => [
          color[0],
          Color(color[1], colorModel)[nextColorModel]().array(),
        ]
      );
      setColors(new Map(nextColors));
      setColorModel(nextColorModel);
    },
    [colors, colorModel, setColorModel, setColors]
  );

  const setShadesCount = useCallback(
    (nextCount: number) => {
      console.log(`set shades count to "${nextCount}"`);
      const previousCount = Array.from(colors.keys())[colors.size - 1];
      const indicesInPercent = Array.from(colors.keys()).map(
        (index) => (index - 1.0) / (previousCount - 1.0)
      );
      const nextIndices = indicesInPercent.map(
        (index) => Math.round((nextCount - 1) * index) + 1
      );
      const nextColors = new Map(
        Array.from(colors.values()).map((value, index) => [
          nextIndices[index],
          value,
        ])
      );
      setColors(nextColors);
    },
    [colors, setColors]
  );

  return {
    shadesCount,
    colors,
    colorModel,
    shades,
    setColor,
    moveColor,
    removeColor,
    changeColorModel,
    setShadesCount,
    showHexValues,
    setShowHexValues,
  };
};

export const [useShades, useShadeColors, useShadeActions] = constate(
  useShadesContext,
  (values) => ({
    colors: values.colors,
    shades: values.shades,
    colorModel: values.colorModel,
    shadesCount: values.shadesCount,
    showHexValues: values.showHexValues,
  }),
  (values) => ({
    setColor: values.setColor,
    moveColor: values.moveColor,
    removeColor: values.removeColor,
    changeColorModel: values.changeColorModel,
    setShadesCount: values.setShadesCount,
    setShowHexValues: values.setShowHexValues,
  })
);

export default useShades;
