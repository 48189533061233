import { ColorModel } from "./urlHelpers";

export const mixColor = (params: {
  color1: number[];
  color2: number[];
  weight: number;
  colorModel: ColorModel;
  easing?: number;
}) => {
  const { color1, color2, weight, colorModel, easing = 1.0 } = params;
  const maxValue = colorModel === ColorModel.rgb ? 255 : 155;

  return color1.map((value1, index) => {
    let start = value1;
    let end = color2[index];
    let normalizedWeight = weight;

    if (start === end) {
      return start;
    } else if (start > end) {
      const temp = start;
      start = end;
      end = temp;
      normalizedWeight = 1 - weight;
    }
    const startInPercent = start / maxValue;
    const endInPercent = end / maxValue;
    const differenceInPercent = endInPercent - startInPercent;

    const mappedStart = Math.pow(startInPercent, easing) * maxValue;
    const mappedEnd = Math.pow(endInPercent, easing) * maxValue;

    const mappingScaling = (end - start) / (mappedEnd - mappedStart);

    return (
      start +
      (Math.pow(
        differenceInPercent * normalizedWeight + startInPercent,
        easing
      ) *
        maxValue -
        mappedStart) *
        mappingScaling
    );
  });
};
