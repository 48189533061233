import Color from "color";

enum ShadesUrlParams {
  model = "model",
  colors = "colors",
  easing = "easing",
}

export enum ColorModel {
  rgb = "rgb",
  cmyk = "cmyk",
  hsl = "hsl",
}

export const parseColorModelFromUrl = () => {
  const searchParams = new URLSearchParams(window.location.search);
  return searchParams.get(ShadesUrlParams.model) as ColorModel;
};

export const parseColorEasingFromUrl = () => {
  const searchParams = new URLSearchParams(window.location.search);
  return parseFloat(searchParams.get(ShadesUrlParams.easing) ?? "1.0");
};

/**
 * Convert colors map into readable string for url.
 * String format like "1.0-0-0_11.255-0-0_21.255-255-255".
 */
export const updateUrl = (model: ColorModel, easing: number, colors: Map<number, number[]>) => {
  const entries = Array.from(colors.entries());
  const colorsString = entries
    .map((entry) => {
      const color = Color(entry[1], model);
      return `${entry[0]}.${color.round().array().join("-")}`;
    })
    .join("_");

  const searchParams = new URLSearchParams(window.location.search);
  if (searchParams.get(ShadesUrlParams.colors) !== colorsString) {
    searchParams.set(ShadesUrlParams.colors, colorsString);
    searchParams.set(ShadesUrlParams.model, model);
    searchParams.set(ShadesUrlParams.easing, easing.toString());
    const url = new URL(window.location.href);
    url.search = searchParams.toString();
    window.history.pushState(null, "", url.toString());
  }
};

/**
 * Convert colors string to color map.
 * String format like "1.0-0-0_11.255-0-0_21.255-255-255".
 */
export const parseColorsFromUrl = () => {
  const searchParams = new URLSearchParams(window.location.search);
  const colorModel = searchParams.get(ShadesUrlParams.model) as ColorModel;
  const colorsString = searchParams.get(ShadesUrlParams.colors);

  // update document title
  document.title = `Shades - ${searchParams.toString()}`;

  if (!colorModel || !colorsString) {
    return null;
  }
  const entries: [number, number[]][] = colorsString
    .split("_")
    .map((entryString) => {
      const index = parseInt(entryString.split(".")[0]);
      const colorFragments = entryString
        .split(".")[1]
        .split("-")
        .map((value) => parseInt(value));
      const color = Color(colorFragments, colorModel);
      return [index, color.array()];
    });
  return new Map(entries);
};
