import { createGlobalStyle } from "styled-components";
import GraphikBoldWoff2 from "./fonts/Graphik-Bold-Web.woff2";
import GraphikRegularWoff2 from "./fonts/Graphik-Regular-Web.woff2";

const Fonts = createGlobalStyle`
	@font-face {
		font-family: BaseFont;
		font-style: normal;
		font-weight: normal;
		src: url('${GraphikRegularWoff2}');
	}
  
  @font-face {
    font-family: BaseFont;
    font-style: italic;
    font-weight: normal;
    src: url('${GraphikRegularWoff2}');
  }

	@font-face {
		font-family: BaseFont;
		font-style: normal;
		font-weight: bold;
		src: url('${GraphikBoldWoff2}');
	}
`;

export default Fonts;
