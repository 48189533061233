import React, { SVGProps } from "react";

const SvgCross = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={96}
    height={96}
    viewBox="0 0 96 96"
    fill="currentColor"
    {...props}
  >
    <path d="M42.6294 53.3706V96H53.3706V53.3706H96V42.6294H53.3706V0H42.6294V42.6294H0V53.3706H42.6294Z" />
  </svg>
);

export default SvgCross;
