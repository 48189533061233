import React from "react";
import styled from "styled-components";
import theme, { breakpoints } from "../designSystem/theme";
import { ColorModel } from "../utils/urlHelpers";
import { useShadeActions, useShadeColors } from "../context/shadesContext";
import { useDebouncedCallback } from "use-debounce";

const Settings = () => {
  const { shadesCount, colorModel, showHexValues } = useShadeColors();
  const { changeColorModel, setShadesCount, setShowHexValues } =
    useShadeActions();

  const debouncedSetShadesCount = useDebouncedCallback((value) => {
    setShadesCount(value);
  }, 700);

  return (
    <StyledSettings>
      <div>
        <h3>Color Mode</h3>
        <select
          defaultValue={colorModel}
          onChange={(event) => {
            changeColorModel(event.target.value as ColorModel);
          }}
        >
          <option value={ColorModel.rgb}>RGB</option>
          <option value={ColorModel.cmyk}>CMYK</option>
          <option value={ColorModel.hsl}>HSL</option>
        </select>
        {colorModel === ColorModel.rgb ? (
          <>
            <br />
            <label className="hex-option">
              <input
                type="checkbox"
                checked={showHexValues}
                onChange={(event) => setShowHexValues(event.target.checked)}
              />
              <span>as HEX</span>
            </label>
          </>
        ) : null}
      </div>

      <div>
        <h3>Shade Count</h3>
        <input
          type="number"
          defaultValue={shadesCount}
          min={2}
          max={500}
          onChange={(event) =>
            debouncedSetShadesCount(parseInt(event.target.value))
          }
        />
      </div>
    </StyledSettings>
  );
};

export const StyledSettings = styled("section")`
  box-sizing: border-box;
  min-width: 100px;
  padding: 20px 40px 40px 20px;
  margin: 0 -20px 40px -20px;
  border: 1px solid ${theme.colors.settingsBorder};
  border-radius: 4px;
  background-color: ${theme.colors.settingsBackground};
  display: flex;
  flex-direction: row;
  align-items: flex-start;

  @media (min-width: ${breakpoints.tablet}px) {
    flex-direction: column;
    align-items: flex-start;
    max-width: 200px;
    padding: 20px 20px 40px 20px;
    margin: 0 0 40px 0;
  }

  > div {
    margin: 0 40px 0 0;

    @media (min-width: ${breakpoints.tablet}px) {
      margin: 20px 0 10px 0;
    }

    &:first-of-type {
      margin-top: 0;
    }
  }

  h3 {
    margin: 0 0 8px 0;
    white-space: nowrap;
  }

  input {
    box-sizing: border-box;
    width: 90px;
    height: 40px;
    padding: 8px;
    border-radius: 4px;
    border: 2px solid black;
    outline: none;
  }

  select {
    box-sizing: border-box;
    width: 90px;
    height: 40px;
    padding: 8px;
    border-radius: 4px;
    border: 2px solid black;
    outline: none;
    background-color: white;
  }

  .hex-option {
    display: flex;
    align-items: center;
    white-space: nowrap;
    margin-top: 10px;

    > input {
      width: 20px;
      height: 20px;
      margin-right: 4px;
    }
  }
`;

export default React.memo(Settings);
