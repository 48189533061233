import { css } from "styled-components";

export const spacing = {
  none: 0,
  space4: 4,
  space8: 8,
  space12: 12,
  space16: 16,
  space20: 20,
  space24: 24,
  space32: 32,
  space48: 48,
  space60: 60,
  space68: 68,
  space96: 96,
};

export const breakpoints = {
  mobile: 375,
  tablet: 768,
  desktop: 992,
  large: 1500,
};

export const colors = {
  text: "rgb(0, 0, 0)",
  settingsBorder: "rgb(230, 230, 230)",
  settingsBackground: "rgb(241, 241, 241)",
  background: "rgb(255, 255, 255)",
};

const theme = {
  baseFontSize: 14,
  breakpoints,
  colors,
  spacing,
  dimensions: {
    padding: {
      mobile: spacing.space24,
      tablet: spacing.space32,
    },
    maxContentWidth: {
      desktop: 940,
      large: 1180,
    },
  },
  typography: {
    baseFont: css`
      font-size: 14px;
      line-height: ${20 / 14};
      font-family: BaseFont, sans-serif;
      font-weight: normal;
    `,
    heading1: css`
      font-size: 32px;
      line-height: ${36 / 32};
      font-family: BaseFont, sans-serif;
      font-weight: bold;
      letter-spacing: -0.4px;
      margin-bottom: ${spacing.space24}px;
    `,
  },
};

export type Theme = typeof theme;

export default theme;
